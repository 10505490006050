export const environment = {
  production: false,
  showDebugMenu: false,
  TOKEN_URL: 'https://layover-api-development.azurewebsites.net/api/v1/auth/token',
  REFRESH_URL: 'https://layover-api-development.azurewebsites.net/api/v1/auth/refreshToken',
  BASE_URL: 'https://layover-api-development.azurewebsites.net',
  API_URL: 'api',
  DEFAULT_LANGUAGE: 'it-IT',
  APP_NAME: 'Admin',
  ENV: 'Dev azure',
  googleApiKey: 'AIzaSyCIlvn4vvV035xuSU0ODlPVyups5guUAfs',
};

