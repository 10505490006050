import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { ApiService } from './api.service';
import { TransportHub } from '../autogenerated/model.autogenerated';

@Injectable({
  providedIn: 'root',
})
export class CacheTransportHubService {

  public transportHubs: TransportHub[] = [];

  constructor(
    private readonly _api: ApiService
  ) {

  }

  public initialize() {
    return this._api.TransportHub_List()
      .pipe(
        tap(x => {
          this.transportHubs = x.items!;
        })
      );
  }

  public filterTransportHubs(filter: string) {
    return this.transportHubs.filter(x =>
      x.name!.toLowerCase().includes(filter.toLowerCase())
      || x.iataCode!.toLowerCase().startsWith(filter.toLowerCase()));
  }
}
